const NotificationHelper = {
  _checkInsText : {
    1  : 'Parabéns pelo teu primeiro check-in! Agora faz o check-in em todos os pontos que passares e habilita-te a prémios e ofertas!',
    2  : 'Dobradinha (2 check-ins). Estás no bom caminho!',
    3  : 'Hat-trick (3 check-ins). Estás no bom caminho!',
    5  : '5 check-ins 🖐 Aos 30 check-ins já podes ganhar prémios!',
    10 : '10 check-ins! Visita mais 20 pontos de investigadores para trocares o teu primeiro prémio.',
    20 : '20 check-ins! Visita mais 10 pontos de investigadores para trocares o teu primeiro prémio.',
    30 : 'Podes trocar uma lembrança NEI Coimbra 2023 (30 check-ins). Mais 20 check-ins e habilitas-te a mais prémios!',
    40 : '40 check-ins! Estás a 10 check-ins de poderes trocar mais prémios!',
    50 : 'Podes trocar mais um prémio (50 check-ins). Visita o nosso ponto de informação e continua, mais 20 check-ins e habilitas-te a ganhar mais prémios!',
    60 : '60 check-ins! Estás a 10 check-ins de poderes trocar mais prémios!',
    70 : 'Podes trocar mais um prémio (70 check-ins). Visita o nosso ponto de informação e continua, mais 10 check-ins e habilitas-te a ganhar mais prémios!',
    75 : 'Estás quase a tornar-te um campeão da ciência! Faz check-in em mais 5 pontos para ganhares mais prémios!',
    80 : 'Podes trocar mais um prémio (80 check-ins). Visita o nosso ponto de informação e continua, estás a 5 check-ins de completares a Rota da Ciência e poderes trocar um fantástico prémio!',
    85 : 'Parabéns, a Rota da Ciência é tua! Mereces fazer parte da nossa equipa de investigação! Vem ter connosco ao Ponto de Informação | European Corner | NEI 2023 para trocares o teu prémio.'
  }
};

NotificationHelper.init = function (checkInCount) {
  notie.setOptions({
    alertTime: 30,
  });
};

NotificationHelper.handleCheckIn = function (checkInCount) {
  if(checkInCount in NotificationHelper._checkInsText)
    setTimeout(() => {
      notie.alert({
        type: 4,
        text: '👍 ' + NotificationHelper._checkInsText[checkInCount] + ' 🎉',
        position: 'bottom'
      });
    }, 500);
};

NotificationHelper.getCheckInText = checkInCount => {
  let index = checkInCount;

  while(index > 0) {
    if(NotificationHelper._checkInsText[index])
      return NotificationHelper._checkInsText[index];

    index--;
  }

  return '';
};
